import { withPreviewResolver } from 'gatsby-source-prismic';
import * as React from 'react';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!';

  console.info('isLoading', isLoading);

  return (
    <div>
      <p>Preview loading...</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `bad-marketing`,
  linkResolver: () => (page) => `/${page.uid}`,
});
